import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

import { PageMeta } from "../components/pageMeta"
import BlockZone from "~blockZone"
import SomedayIcon from '../svg/someday'
import { PortableTextBlock } from "../components/sanity/portableTextBlock"

import { ServicesBlock, TeamMember, TextBlock } from "../components/blockZone/blocks"

// import { textBlockStyle } from "../../../hooks"
// import { blockWidth } from "../../../hooks"
import { textBlockStyle, blockWidth } from "../hooks"
import AspectImage from "../components/media/aspectImage"


const ServicePage = ({ data: { page } }) => {
console.log('servicespage', page)

  const [body, setBody] = useState(false)


  useEffect(() => {
    const b = page.bodyCopy.map(x => {
      return ({
        ...x,
        markDefs: []
      })
    })
    setBody(b);
  }, [])

  return (
    <>

      <section class="section pt-12 md:pt-extra-large pb-6 md:pb-large">
        <div class="prose col-span-6 prose headings--rte textBlock_headingHero col-span-6 md: col-span-12">
          <h1 class="font-heading">{page.heading}</h1>
        </div>
      </section>


      <ServicesBlock servicesTitle={page.title} serviceItem={page.services} />


      <section className="flex flex-col md:grid grid-cols-12 w-screen pt-24">
        <div className=" col-start-2 col-span-3 row-start-1 h-full pl-5 type-services_body flex flex-col items-center justify-center" >

          {body &&
            <PortableTextBlock text={body} />

          }

        </div>

        <div className="col-start-6 col-span-7 row-start-1">
          <AspectImage image={page.image.asset.gatsbyImageData} aspect={"57.14"} />
        </div>
      </section>



    <div className="my-24">
      <TeamMember {...page.teamMembers}/>
      </div>

      {/* <ServicesTeam /> */}


    </>
  )
}

const ServicesTeam = () => {
  return (
    <section className="md:grid grid-cols-12 px-5 gap-2 pt-24">
      <div className="flex flex-col col-start-2 col-span-6">
        <h3 className="type-services_heading">Our Team</h3>

        <div className="w-full pt-5">
          <p className="type-services_member">Henry Huwat</p>
        </div>

      </div>
    </section>
  )
}

export default ServicePage

export const pageQuery = graphql`
  query ServicePageQuery {
    page: sanityServicesPage {
      title
      heading
      services{
        serviceName
        serviceDetails {
            _type
            style
              children {
                text
                _key
                _type
                marks
            }
          }
      }
      bodyCopy {
        style
        # _rawChildren(resolveReferences: {maxDepth: 10})
        _key
        _type
        children {
          _key
          _type
          marks
          text
        }

      }
      image{
        asset{
          gatsbyImageData( placeholder: DOMINANT_COLOR)
        }
      }
      teamMembers{
        _key
          _type
          _rawTeamMember(resolveReferences: {maxDepth: 10})
          teamMember {
            teamMemberName
            teamMemberNameRole {
              _rawChildren(resolveReferences: {maxDepth: 10})
              style
              children {
                text
                _key
                _type
                marks
              }
            }
            _key
            _type
          }
          teamTitle
      }
      meta {
        ...MetaCardFields
      }
    }
  }
`
